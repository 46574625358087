<template>
    <div>
        <!-- 首页固定区 -->
        <!-- <div class="index-wrap" :style="{ background: backgroundColor }"> -->
        <div></div>

        <!-- <div class="index-wrap"> -->
        <!-- <div class="index"> -->
        <div class="banner isshow" style="cursor: pointer" :style="{ width: isMobile ? '100%' : '1200px',margin:'0 auto' }">
            <el-image src="https://s.alicdn.com/@sc02/kf/H33873b0b467541dd894036bcd4dce650r.jpg?hasNWGrade=1" fit="cover" @click="goCategory" />
            <!-- <el-carousel height="500px" arrow="hover" v-loading="loadingAd" @change="handleChange">
                        <el-carousel-item v-for="item in adList" :key="item.adv_id">
                            <el-image :src="$img(item.adv_image)" fit="cover" @click="$router.pushToTab(item.adv_url.url)" />
                        </el-carousel-item>
                    </el-carousel> -->
        </div>
        <!-- </div> -->
        <!-- </div> -->

        <div class="content">
            <!-- 限时秒杀 -->
            <div class="content-div" v-if="addonIsExit.seckill == 1 && listData.length > 0 && (city.id == 0 || !city)">
                <div class="seckill-wrap">
                    <div class="seckill-time">
                        <div class="seckill-time-left">
                            <i class="iconfont iconmiaosha1 ns-text-color"></i>
                            <span class="seckill-time-title ns-text-color">限时秒杀</span>
                            <span>{{ seckillText }}</span>
                            <count-down class="count-down" v-on:start_callback="countDownS_cb()" v-on:end_callback="countDownE_cb()" :currentTime="seckillTimeMachine.currentTime" :startTime="seckillTimeMachine.startTime" :endTime="seckillTimeMachine.endTime" :dayTxt="'：'" :hourTxt="'：'" :minutesTxt="'：'" :secondsTxt="''"></count-down>
                        </div>
                        <div class="seckill-time-right" @click="$router.push('/promotion/seckill')">
                            <span>更多商品</span>
                            <i class="iconfont iconarrow-right"></i>
                        </div>
                    </div>
                    <div class="seckill-content" @click="clickProps($event)">
                        <vue-seamless-scroll :data="listData" :class-option="optionLeft" class="seamless-warp2">
                            <ul class="item" :style="{ width: 250 * listData.length + 'px' }">
                                <li v-for="(item, index) in listData" :key="index">
                                    <div class="seckill-goods" :data-obj="JSON.stringify(item)">
                                        <div class="seckill-goods-img" :data-obj="JSON.stringify(item)">
                                            <img :src="goodsImg(item.goods_image)" @error="imageError(index)" :data-obj="JSON.stringify(item)" />
                                        </div>
                                        <p :data-obj="JSON.stringify(item)">{{ item.goods_name }}</p>
                                        <div class="seckill-price-wrap" :data-obj="JSON.stringify(item)">
                                            <p class="ns-text-color" :data-obj="JSON.stringify(item)">
                                                ￥
                                                <span :data-obj="JSON.stringify(item)">{{ item.seckill_price }}</span>
                                            </p>
                                            <p class="primary-price" :data-obj="JSON.stringify(item)">￥{{ item.price }}</p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </vue-seamless-scroll>
                    </div>
                </div>
            </div>

            <!-- 楼层区 -->
            <div class="content-div" v-if="city.id == 0 || !city">
                <div class="floor">
                    <div class="isshow">
                        <div style="position: relative">
                            <div ref="aboutUs" class="about_us" @click="goAboutUsHandle"></div>
                            <img ref="bannerImg" src="@/assets/index_img/首页_14.jpg" />
                        </div>

                        <img src="@/assets/index_img/首页_17.jpg" style="cursor: pointer" @click="goShoppingPage" />
                        <div class="nine-grid" style="position: relative">
                            <!-- 220   -->
                            <img ref="nineGridMapRef" usemap="#nineGridMap" src="@/assets/index_img/123.jpg" />
                            <map name="nineGridMap">
                                <area shape="rect" coords="25,198,396,559" href="/web/sku-5544" alt="格子1" />
                                <area shape="rect" coords="421,198,792,559" href="/web/sku-614" alt="格子2" />
                                <area shape="rect" coords="817,198,1188,559" href="/web/sku-5546" alt="格子3" />

                                <!-- 第二行 -->
                                <area shape="rect" coords="25,574,396,935" href="/web/sku-1390" alt="格子4" />
                                <area shape="rect" coords="421,574,792,935" href="/web/sku-5561" alt="格子5" />
                                <area shape="rect" coords="817,574,1188,935" href="/web/sku-5575" alt="格子6" />

                                <!-- 第三行 -->
                                <area shape="rect" coords="25,950,396,1311" href="/web/sku-5555" alt="格子7" />
                                <area shape="rect" coords="421,950,792,1311" href="/web/sku-1582" alt="格子8" />
                                <area shape="rect" coords="817,950,1188,1311" href="/web/sku-5559" alt="格子9" />
                            </map>
                        </div>
                        <img src="@/assets/index_img/首页_23.jpg" />
                    </div>
                    <div v-for="(item, index) in floorList" :key="index" class="floor_item">
                        <img :src="images[item.title]" class="isshow" style="cursor: pointer" @click="goPreojectListPage" />
                        <tile :data="item"></tile>
                    </div>
                    <div class="isshow">
                        <img src="@/assets/index_img/首页_62.jpg" />
                        <img src="@/assets/index_img/首页_65.jpg" style="cursor: pointer" @click="goAboutUsPage" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import indexMain from "./components/index-main.vue"
    import tile from "./components/tile.vue"
    import index from "./_index/index.js"
    import { mapGetters, mapActions } from "vuex"

    export default {
        name: "index",
        components: {
            indexMain,
            tile
        },
        mixins: [index],
        data() {
            return {
                images: {
                    Pump: require("@/assets/index_img/首页_26.jpg"),
                    Accessory: require("@/assets/index_img/首页_32.jpg"),
                    Valve: require("@/assets/index_img/首页_38.jpg")
                    /*				a: "@/assets/index_img/首页_44.jpg",
    b: "@/assets/index_img/首页_50.jpg",
    c: "@/assets/index_img/首页_56.jpg"*/
                },
                imageIds: ["Pump", "Valve", "Accessory"]
            }
        },
        computed: {
            ...mapGetters("device", ["isMobile"]) // 获取 isMobile 状态
        },
        mounted() {
            // console.log("floorList: "+floorList)
            window.addEventListener("resize", this.updateAreaCoords)
            window.addEventListener("resize", this.handleImageLoad)
            console.log("123", index.data().floorList)
            setTimeout(() => {
                this.updateAreaCoords()
                this.handleImageLoad()
            }, 1000)
        },
        beforeDestroy() {
            window.removeEventListener("resize", this.updateAreaCoords)
            window.removeEventListener("resize", this.handleImageLoad)
        },
        methods: {
            getImageSrc(id) {
                // 根据id返回图片路径
                return this.images[id]
            },
            goAboutUsPage(){
                const url = `/cms/contactUs`
                window.location.href = url
            },
            handleImageLoad() {
                this.$nextTick(() => {
                    const img = this.$refs.bannerImg // 获取图片元素
                    const aboutUs = this.$refs.aboutUs // 获取 about_us 元素

                    if (img && aboutUs) {
                        const imgWidth = img.clientWidth // 图片的实际宽度
                        const imgHeight = img.clientHeight // 图片的实际高度

                        // 计算 about_us 的位置和大小
                        const aboutUsWidth = imgWidth // about_us 的宽度与图片一致
                        const aboutUsHeight = (477 / 1183) * imgHeight // 根据比例计算高度
                        const aboutUsTop = (650 / 1183) * imgHeight // 根据比例计算 top

                        // 设置 about_us 的样式
                        aboutUs.style.width = `${aboutUsWidth}px`
                        aboutUs.style.height = `${aboutUsHeight}px`
                        aboutUs.style.top = `${aboutUsTop}px`
                        aboutUs.style.left = "0"
                    }
                })
            },
            changeLanguage(lang) {
                this.$i18n.locale = lang
            },
            goAboutUsHandle() {
                let lang = this.$route.query.lang
                if (!lang) {
                    lang = "en"
                }
                console.log("123", lang)
                const url = `/cms/about_us?lang=${lang}`
                window.location.href = url
                // this.$router.push(url)
                console.log("?")
            },
            goShoppingPage() {
                let lang = this.$route.query.lang
                if (!lang) {
                    lang = "en"
                }
                const url = `/category?lang=${lang}`
                window.location.href = url
            },
            goPreojectListPage() {
                let lang = this.$route.query.lang
                if (!lang) {
                    lang = "en"
                }
                const url = `/categorySpec?category_id=1&level=1&lang=${lang}`
                window.location.href = url
            },
            handleClick(num) {
                console.log("-->", num)
            },
            goCategory() {
                let lang = this.$route.query.lang
                if (!lang) {
                    lang = "en"
                }
                const url = `/category?&lang=${lang}`
                window.location.href = url
            },
            updateAreaCoords() {
                this.$nextTick(() => {
                    const img = this.$refs.nineGridMapRef

                    const imgRect = img.getBoundingClientRect()
                    const imgWidth = imgRect.width
                    const imgHeight = imgRect.height
                    const originalWidth = 1200
                    const originalHeight = 1353.38
                    const scaleX = imgWidth / originalWidth
                    const scaleY = imgHeight / originalHeight
                    const areas = document.querySelectorAll(".nine-grid area")
                    console.log("areas", areas)

                    // 定义九宫格的原始坐标（基于原始图片尺寸）
                    const originalCoords = [
                        { x1: 25, y1: 198, x2: 396, y2: 559 }, // 格子1
                        { x1: 421, y1: 198, x2: 792, y2: 559 }, // 格子2
                        { x1: 817, y1: 198, x2: 1188, y2: 559 }, // 格子3
                        { x1: 25, y1: 574, x2: 396, y2: 935 }, // 格子4
                        { x1: 421, y1: 574, x2: 792, y2: 935 }, // 格子5
                        { x1: 817, y1: 574, x2: 1188, y2: 935 }, // 格子6
                        { x1: 25, y1: 950, x2: 396, y2: 1311 }, // 格子7
                        { x1: 421, y1: 950, x2: 792, y2: 1311 }, // 格子8
                        { x1: 817, y1: 950, x2: 1188, y2: 1311 } // 格子9
                    ]
                    // 更新每个 <area> 的 coords
                    areas.forEach((area, index) => {
                        const { x1, y1, x2, y2 } = originalCoords[index]
                        const scaledX1 = Math.round(x1 * scaleX)
                        const scaledY1 = Math.round(y1 * scaleY)
                        const scaledX2 = Math.round(x2 * scaleX)
                        const scaledY2 = Math.round(y2 * scaleY)

                        area.coords = `${scaledX1},${scaledY1},${scaledX2},${scaledY2}`
                        console.log("area.coords ", area.coords)
                    })
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "./_index/index.scss";
</style>

<style lang="scss" scoped>
    .index-wrap {
        .index {
            width: $width;
        }
    }

    .count-down {
        span {
            display: inline-block;
            width: 22px;
            height: 22px;
            line-height: 22px;
            text-align: center;
            background: #383838;
            color: #ffffff;
            border-radius: 2px;
        }
    }

    .more {
        width: 1200px;
        margin: auto;
        display: flex;
        justify-content: flex-end;

        a {
            color: #ff547b;
        }
    }

    .goods-info {
        width: 1200px;
        margin: 5px auto 40px;
        display: flex;
        flex-wrap: wrap;

        .item {
            width: 202px;
            margin: 10px 20px 0 0;
            border: 1px solid #eeeeee;
            padding: 10px;
            position: relative;

            &:nth-child(5 n) {
                margin-right: initial !important;
            }

            &:hover {
                border: 1px solid $base-color;
            }

            .img-wrap {
                width: 198px;
                height: 198px;
                cursor: pointer;
            }

            .goods-name {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                cursor: pointer;

                &:hover {
                    color: $base-color;
                }
            }

            .price-wrap {
                display: flex;
                align-items: center;

                .price {
                    display: flex;
                    color: $base-color;
                    font-size: $ns-font-size-lg;

                    p {
                        font-size: $ns-font-size-base;
                        display: flex;
                        align-items: flex-end;
                    }
                }

                .market-price {
                    color: #838383;
                    text-decoration: line-through;
                    margin-left: 10px;
                }
            }

            .sale-num {
                display: flex;
                color: #838383;

                p {
                    color: #4759a8;
                }
            }

            .saling {
                display: flex;
                font-size: $ns-font-size-sm;
                line-height: 1;

                .free-shipping {
                    background: $base-color;
                    color: #ffffff;
                    padding: 3px 4px;
                    border-radius: 2px;
                    margin-right: 5px;
                }

                .promotion-type {
                    color: $base-color;
                    border: 1px solid $base-color;
                    display: flex;
                    align-items: center;
                    padding: 1px;
                }
            }
        }
    }
    .about_us {
        // height: 477px;
        // right: auto;
        // width: 100%;
        // border: none;
        // left: 0px;
        // line-height: normal;
        // padding: 0px;
        // top: 650px;
        // background: none;
        // z-index: 1;
        // position: absolute;
        // cursor: pointer;
        width: 100%;
        position: absolute;
        cursor: pointer;
        z-index: 1;
        box-sizing: border-box;
    }
    /* 适用于手机设备 */
    @media only screen and (max-width: 600px) {
        .isshow {
            // display: none;
            width: 100%;
        }
    }
</style>
